import React, { FC, useState, createContext } from 'react'

import * as shippingLabelsApi from "services/shippingLabels";
// import Notification from 'utils/notification'

export interface IShippingLabel {
  id: any
  uid: any
  projectId: any
  status: any
  patient: any
}

export interface IShippingLabelsContext {
  labelsUrl: IShippingLabel[]
  shipmentTracking: any
  loading: boolean
  error: any
  generateShippingLabels: (data: any, successCallback?: any, waitCallback?: any) => void
  cancelShippingLabels: (data: any, callback?: any) => void
  getShipmentLabelsTracking: (data: any, callback?: any) => void
  resetLabelsTracking: () => void
}

const ShippingLabelsContext = createContext<IShippingLabelsContext>({
  labelsUrl: [],
  shipmentTracking: [],
  loading: false,
  error: null,
  generateShippingLabels: () => {},
  cancelShippingLabels: () => {},
  getShipmentLabelsTracking: () => {},
  resetLabelsTracking: () => {},
})

const ShippingLabelsProvider: FC = props => {
  const [state, setState] = useState({
    labelsUrl: [],
    shipmentTracking: [],
    error: null,
    loading: false,
  })

  const generateShippingLabels = async (data: any, successCallback: any, waitCallback: any): Promise<void> => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }))

      const { status, url } = await shippingLabelsApi.generateShippingLabels(data)

      if (status === 'WAIT') {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }))

        if (waitCallback) {
          await waitCallback()
        }
      } else if (status === 'READY') {
        setState(prevState => ({
          ...prevState,
          loading: false,
          labelsUrl: url,
        }))

        if (successCallback) {
          await successCallback(url)
        }
      } else {
        throw new Error('Error happened while generating labels')
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot generate shipping labels',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const cancelShippingLabels = async (data: any, callback: any): Promise<void> => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }))

      const result = await shippingLabelsApi.cancelShippingLabels(data)

      setState(prevState => ({
        ...prevState,
        loading: false,
      }))

      // result.items.map((item: any) =>
        // Notification({
        //   type: item.success ? 'success' : 'warning',
        //   message: item.success ? 'Shipping labels were canceled!' : item.message,
        //   ...(!item.success ? { description: `Device ID: ${item.deviceId}` } : {}),
        // })
      // )

      if (callback) {
        await callback()
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot cancel shipping labels',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const getShipmentLabelsTracking = async (data: any, callback: any): Promise<void> => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }))

      const result = await shippingLabelsApi.getShipmentLabelsTracking(data)

      setState(prevState => ({
        ...prevState,
        loading: false,
        shipmentTracking: result.devices,
      }))

      if (callback) {
        await callback()
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get shipping labels tracking',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const resetLabelsTracking = (): void => {
    setState(prevState => ({
      ...prevState,
      shipmentTracking: [],
    }))
  }

  return (
    <ShippingLabelsContext.Provider
      value={{
        labelsUrl: state.labelsUrl,
        shipmentTracking: state.shipmentTracking,
        loading: state.loading,
        error: state.error,
        generateShippingLabels,
        cancelShippingLabels,
        getShipmentLabelsTracking,
        resetLabelsTracking,
      }}
      {...props}
    />
  )
}

const useShippingLabels = (): IShippingLabelsContext => React.useContext(ShippingLabelsContext)

export { ShippingLabelsProvider, useShippingLabels }
