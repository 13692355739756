import { httpClient } from 'services/index'

export const generateShippingLabels = (data: any): Promise<any> =>
  httpClient.post('/shipping-labels', data).then(_ => _.data)

export const cancelShippingLabels = (data: any): Promise<any> =>
  httpClient.delete('/shipping-labels', { data }).then(_ => _.data)

export const getShipmentLabelsTracking = (data: any): Promise<any> =>
  httpClient.post('/shipment-tracking', data).then(_ => _.data)

export const printShipmentLabels = (data: any): Promise<any> =>
  httpClient.post('/shipping-labels-print', data).then(_ => _.data)
