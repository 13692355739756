import React, { PureComponent } from "react";
import "./loading.scss";

export default function () {
  return (
    
    <div className={"loader"}>
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
