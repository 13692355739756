interface Config {
  api_url: string;
  client_id: string;
  pool_id: string;
  region: string;
}

// const devConfig = {
const config = {
  api_url: process.env.REACT_APP_API_URL as string,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
  pool_id: process.env.REACT_APP_COGNITO_POOL_ID as string,
  region: process.env.REACT_APP_COGNITO_REGION as string,
};

// const prodConfig = {
//   api_url: process.env.API_URL as string,
//   client_id: process.env.COGNITO_CLIENT_ID as string,
//   pool_id: process.env.COGNITO_POOL_ID as string,
//   region: process.env.COGNITO_REGION as string,
// };


// const config: Config =
//   process.env.NODE_ENV === "production" ? devConfig : devConfig;

export const API_URL = config.api_url || "";
export const COGNITO_CLIENT_ID = config.client_id || "";
export const COGNITO_POOL_ID = config.pool_id || "";
export const COGNITO_REGION = config.region || "";
