/* Nesting is our friend! */
/* https://github.com/facebook/react/issues/14620#issuecomment-455652349 */

import React, { FC } from 'react'

import { AuthProvider } from 'providers/Auth'
import { ProjectsProvider } from 'providers/Projects'
import { DevicesProvider } from 'providers/Devices'
// import { FormsProvider } from 'providers/forms'
// import { ContactsProvider } from 'providers/contacts'
// import { AnalytesProvider } from 'providers/analytes'
// import { LaboratoriesProvider } from 'providers/laboratories'
import { ParticipantsProvider } from 'providers/Participants'
// import { CustomersProvider } from 'providers/customers'
import { ShippingLabelsProvider } from 'providers/ShippingLabels'
// import { StatisticsProvider } from 'providers/statistics'

const providers = [
  ProjectsProvider,
  AuthProvider,
  DevicesProvider,
  // FormsProvider,
  // ContactsProvider,
  // AnalytesProvider,
  // LaboratoriesProvider,
  ParticipantsProvider,
  // CustomersProvider,
  ShippingLabelsProvider,
  // StatisticsProvider,
]

const AppProviders: FC<any> = ({ children }) =>
  providers.reduce((acc, Provider) => <Provider>{acc}</Provider>, children)

export default AppProviders
