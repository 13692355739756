import React, { FC, useState, createContext, useReducer } from "react";

import * as projectsApi from "services/projects";
// import Notification from 'utils/notification'
import { PROJECT_STATUS } from "utils/constants";
import { reducer } from "utils/functions";
import * as _I from "utils/types";

const ProjectsContext = createContext<_I.ProjectsContext>({
  projects: [],
  project: null,
  loading: false,
  error: null,
  getProjects: () => {},
  getProject: () => {},
  createProject: () => {},
  updateProject: () => {},
  resetProject: () => {},
});

const ProjectsProvider = (props) => {
  const [state, setState]: [any, React.Dispatch<any>] = useReducer(reducer, {
    projects: [],
    project: {},
    error: null,
    loading: false,
  });

  const checkProjectsStatus = async (projects: any) => {
    setTimeout(async () => {
      const updatedProjects = await Promise.all(
        projects.map(
          async (project: any): Promise<any> => {
            const status = await projectsApi.createProjectDB({
              projectId: project.id,
            });
            if (status.tableStatus === "ACTIVE") {
              return {
                ...project,
                status: PROJECT_STATUS.active,
              };
            }

            return {
              ...project,
              status: PROJECT_STATUS.creating,
            };
          }
        )
      );

      // setState(prevState => ({
      //   ...prevState,
      //   projects: updatedProjects as any,
      // }))

      setState({ projects: updatedProjects as any });

      const isAllActive =
        updatedProjects.filter((project: any) => project.status === "creating")
          .length === 0;

      if (!isAllActive) {
        checkProjectsStatus(updatedProjects);
      }
    }, 2000);
  };

  const getProjects = async (params: any, callback: any): Promise<void> => {
    try {
      setState({ loading: true });

      const { items } = await projectsApi.getProjects(params);

      setState({
        loading: false,
        projects: items.map((item: any) => ({
          ...item,
          status: PROJECT_STATUS.fetching,
        })),
      });

      checkProjectsStatus(items);

      if (callback) {
        await callback();
      }

    } catch (e) {
      setState({
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      });

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get projects list',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  };

  const getProject = async (id: any, callback: any): Promise<void> => {
    try {
      setState({ loading: true });

      const result = await projectsApi.getProject(id);

      setState({
        project: result.item,
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      });

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get project details',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  };

  const resetProject = async (callback: any): Promise<void> => {
    setState({ project: {} });

    if (callback) {
      await callback();
    }
  };

  const createProject = async (data: any, callback: any): Promise<void> => {
    try {
      setState({ loading: true });

      const { projectId } = await projectsApi.createProject(data);

      if (projectId) {
        await projectsApi.createProjectDB({ projectId, create: "true" });
      }

      setState({ loading: false });

      // Notification({
      //   type: 'success',
      //   message: 'New project successfully created',
      // })

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      });

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get project details',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  };

  const updateProject = async (
    id: any,
    data: any,
    callback: any
  ): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      await projectsApi.updateProject(id, data);

      setState({ loading: false });

      // Notification({
      //   type: 'success',
      //   message: 'Project was successfully updated',
      // })

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      });

      // Notification({
      //   type: 'error',
      //   message: 'Cannot update project',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects: state.projects,
        project: state.project,
        loading: state.loading,
        error: state.error,
        getProjects,
        getProject,
        createProject,
        updateProject,
        resetProject,
      }}
      {...props}
    />
  );
};

const useProjects = (): _I.ProjectsContext => React.useContext(ProjectsContext);

export { ProjectsProvider, useProjects };
