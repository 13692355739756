import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AppProviders from "providers/AppProviders";
import * as routes from "utils/routes";
import ProtectedRoute from "components/ProtectedRoute";
import Layout from "./layout";
import Loading from "components/Loading";
import "@progress/kendo-theme-default/dist/all.css";

const lazyLoad = (page: any) => {
  return lazy(page);
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <AppProviders>
          <Layout>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Redirect exact from="/" to={routes.ROUTES.PROJECTS.PATH} />
                <Route
                  path={routes.ROUTES.LOGIN.PATH}
                  exact
                  component={lazyLoad(routes.ROUTES.LOGIN.PAGE)}
                />
                <Route
                  path={routes.ROUTES.LOGOUT.PATH}
                  exact
                  component={lazyLoad(routes.ROUTES.LOGOUT.PAGE)}
                />
                <Route
                  path={routes.ROUTES.CONFIRM_PASSWORD.PATH}
                  exact
                  component={lazyLoad(routes.ROUTES.CONFIRM_PASSWORD.PAGE)}
                />
                <Route
                  path={routes.ROUTES.RESET_PASSWORD.PATH}
                  exact
                  component={lazyLoad(routes.ROUTES.RESET_PASSWORD.PAGE)}
                />
                {/* <Route
                path={routes.ROUTES.PROJECTS.PATH}
                exact
                component={lazyLoad(routes.ROUTES.PROJECTS.PAGE)}
              />
              <Route
                path={routes.ROUTES.PROJECT.PATH}
                exact
                component={lazyLoad(routes.ROUTES.PROJECT.PAGE)}
              /> */}
                <ProtectedRoute
                  page={routes.ROUTES.CHANGE_PASSWORD.PAGE}
                  path={routes.ROUTES.CHANGE_PASSWORD.PATH}
                />
                <ProtectedRoute
                  page={routes.ROUTES.PROJECTS.PAGE}
                  path={routes.ROUTES.PROJECTS.PATH}
                />
                <ProtectedRoute
                  page={routes.ROUTES.PROJECT.PAGE}
                  path={routes.ROUTES.PROJECT.PATH}
                />
                {/* <ProtectedRoute {...routes.ROUTES.PROJECT} /> */}

                {/* {Object.values(routes.ROUTES).map((route) => {
                return route.PROTECTED ? (
                  <ProtectedRoute key={route.PATH} {...route} />
                ) : (
                  <Route
                    key={route.PATH}
                    exact
                    path={route.PATH}
                    component={lazyLoad(route.PAGE)}
                  />
                );
              })} */}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Layout>
        </AppProviders>
      </SnackbarProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function NotFound() {
  return (
    <div className="not-found-wrapper">
      <span className="not-found" />
        ...Page not found!
    </div>
  );
}
