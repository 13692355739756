import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import Auth from "utils/cognito";
import messages from "utils/notificationMessages";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { actionTypes, AWS_CHALLENGE_NAMES } from "utils/constants";
import { ALERT_TYPES } from "utils/constants";
import { notification } from "utils/functions";
import { reducer } from "utils/functions";
import { ROUTES as routes } from "utils/routes";
import * as _I from "utils/types";

// https://docs.amplify.aws/lib/auth/mfa/q/platform/js

const AuthContext = createContext<_I.AuthContext>({
  user: null,
  action: null,
  login: () => {},
  logout: () => {},
  forgotPassword: () => {},
  changePassword: () => {},
  confirmPassword: () => {},
  loading: false,
  error: null,
});

//-------------------------------------------------------/

const AuthProvider = (props: any) => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const [state, setState]: [any, React.Dispatch<any>] = useReducer(reducer, {
    action: null,
    user: null,
    error: null,
    loading: false,
    populated: false,
  });

  useEffect(() => {
    !!state.error && setState({ error: null });
  }, [pathname]);
  const { enqueueSnackbar } = useSnackbar();

  function setNotification(m, t?) {
    notification(m, enqueueSnackbar, t);
  }

  const getLocalUser = async (): Promise<void> => {
    let user: string;

    try {
      const result = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });

      user = result.attributes.email;
    } catch (e) {
      console.log("User is NOT logged in", e);
      user = "";
    }

    setState({ user, populated: true });
  };

  const handleLoginResponse = async (
    user: any,
    email: string
  ): Promise<void> => {
    setState({ loading: false });

    if (
      user.getSignInUserSession() === null &&
      typeof user.challengeName === "string"
    ) {
      if (user.challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
        const callback = async (newPassword: string): Promise<void> => {
          setState({ loading: true });

          try {
            const result = await Auth.completeNewPassword(
              user,
              newPassword,
              null
            );
            await handleLoginResponse(result, email);
          } catch (e) {
            setNotification(e.message);
            console.log(e.message);

            setState({
              loading: false,
              error: true,
            });

            return e;
          }
        };

        setState({
          action: { type: actionTypes.SET_PASSWORD, callback },
        });
      } else if (user.challengeName === AWS_CHALLENGE_NAMES.MFA_SETUP) {
        const callback = async (code: string): Promise<void> => {
          setState({ loading: true });

          try {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, "TOTP");
            await handleLoginResponse(user, email);
          } catch (e) {
            // Notification({ type: "error", message: e.message });
            console.log("mfa setup", e.message);
            setNotification(e.message);

            setState({
              loading: false,
              error: null,
            });

            return e;
          }
        };

        const code = await Auth.setupTOTP(user);

        setState({
          action: { type: actionTypes.SETUP_MFA, callback, code, email },
        });
      } else if (
        user.challengeName === AWS_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA
      ) {
        const callback = async (code: string): Promise<void> => {
          setState({ loading: true });

          try {
            const result = await Auth.confirmSignIn(
              user,
              code,
              "SOFTWARE_TOKEN_MFA"
            );
            await handleLoginResponse(result, email);
          } catch (e) {
            setNotification(messages.errors.INCORRECT_MFA);
            console.log("software token", e.message);

            setState({
              loading: false,
              error: e.message,
            });
            return e;
          }
        };

        setState({
          action: { type: actionTypes.ENTER_CODE, callback },
        });
      }
    } else {
      setState({
        action: null,
        user: user.username,
        loading: false,
        error: null,
      });

      await getLocalUser();

      history.push(routes.PROJECTS.PATH);
    }
  };

  async function login(email: string, password: string) {
    setState({ loading: true });

    try {
      const user = await Auth.signIn(email, password);
      await handleLoginResponse(user, email);
    } catch (e) {
      console.log("login res message", e);
      if (e?.code === "PasswordResetRequiredException") {
        setNotification("Password reset required", ALERT_TYPES.WARNING);
        setState({ loading: false });
        history.push({
          pathname: routes.CONFIRM_PASSWORD.PATH,
          state: { email },
        });
        return;
      }
      setState({
        loading: false,
        error: e,
      });
      setNotification(e.message);
      return e;
    }
  }

  async function logout() {
    await Auth.signOut({ global: true });

    localStorage.clear();

    setState({
      user: null,
    });
  }

  async function forgotPassword(email: string) {
    setState({ loading: true });
    
    console.log("asdff forgot psswrd", email)
    
    try {
      const res = await Auth.forgotPassword(email);

      history.push({
        pathname: routes.CONFIRM_PASSWORD.PATH,
        state: { email },
      });
      setNotification(messages.info.CHECK_EMAIL, ALERT_TYPES.WARNING);
      setState({ loading: false });
    } catch (e) {
      setState({
        loading: false,
        error: e,
      });
      setNotification(e.message);
      return e;
    }
  }

  const confirmPassword = async (
    password: string,
    email: string,
    code: string
  ) => {
    setState({ loading: true });

    try {
      await Auth.forgotPasswordSubmit(email, code, password);

      history.push(routes.LOGIN.PATH);
      setNotification(messages.success.NEW_PASSWORD_SET, ALERT_TYPES.SUCCESS);
      setState({ loading: false });
    } catch (e) {
      const res = e?.response?.data?.error || e;
      setState({
        loading: false,
        error: e,
      });
      setNotification(res.message);
      setState({ error: res });
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
    user: CognitoUser
  ) => {
    setState({ loading: true });

    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      history.push(routes.LOGIN.PATH);
      setNotification(messages.success.NEW_PASSWORD_SET, ALERT_TYPES.SUCCESS);
      setState({ loading: false });
    } catch (e) {
      setState({
        loading: false,
        error: e,
      });
      const res = e?.response?.data?.error || e;
      setNotification(res.message);
      setState({ error: res });
    }
  };

  useEffect(() => {
    getLocalUser();
  }, []);

  const memoizedUser = useMemo(
    () => (state.user ? { username: state.user } : null),
    [state.user]
  );

  if (!state.populated) {
    // return null; // check local env first, load user session - hide screen until then
  }

  return (
    <AuthContext.Provider
      value={{
        user: memoizedUser,
        action: state.action,
        login,
        logout,
        forgotPassword,
        changePassword,
        confirmPassword,
        loading: state.loading,
        error: state.error,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = (): _I.AuthContext => useContext(AuthContext);

export { AuthProvider, useAuth };
