import React, { lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "providers/Auth";
import * as routes from "utils/routes";

export default function ({ path, page }) {
  const { user } = useAuth();
  const Page = lazy(page);

  return (
    <Route
      path={path}
      exact={true}
      render={({ location }) =>
        user ? (
          <Page />
        ) : (
          <Redirect
            to={{
              pathname: routes.ROUTES.LOGIN.PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

