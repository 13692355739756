import * as _I from "utils/types";


export const ROUTES: _I.RoutePageMap = {
  CHANGE_PASSWORD: {
    // PAGE: () => import("pages/ForgotPassword"),
    PAGE: () => import("pages/Login/components/ChangePassword"),
    PATH: "/change-password",
    PROTECTED: true,
  },
  CONFIRM_PASSWORD: {
    PAGE: () => import("pages/Login"),
    PATH: "/confirm",
    PROTECTED: false,
  },
  LOGIN: {
    PAGE: () => import("pages/Login"),
    PATH: "/login",
    PROTECTED: false,
  },
  LOGOUT: {
    PAGE: () => import("pages/Projects/AllProjects"),
    PATH: "/logout",
    PROTECTED: true,
  },
  PROJECT: {
    PAGE: () => import("pages/Projects/Project"),
    PATH: "/project/:projectId",
    PROTECTED: true,
  },
  PROJECTS: {
    PAGE: () => import("pages/Projects/AllProjects"),
    PATH: "/projects",
    PROTECTED: true,
  },
  RESET_PASSWORD: {
    // PAGE: () => import("pages/ForgotPassword"),
    PAGE: () => import("pages/Login"),
    PATH: "/reset-password",
    PROTECTED: false,
  },
  SETTINGS: {
    PAGE: () => import("pages/Projects/AllProjects"),
    PATH: "/settings",
    PROTECTED: true,
  },
};

export const getRoutePath = (routePage: _I.RoutePage, values: any) => {
  return routePage.PATH.replace(
    /:([a-zA-Z]+)/g,
    (match, key) => values[key] || match
  );
};
