import { httpClient } from 'services/index'

export const getPatients = (params: any): Promise<any> => httpClient.get('/patients', { params }).then(_ => _.data)

export const getPatient = (id: any): Promise<any> => httpClient.get(`/patients/${id}`).then(_ => _.data)

export const createPatient = (data: any): Promise<any> => httpClient.post('/patients', data).then(_ => _.data)

export const updatePatient = (id: any, data: any): Promise<any> =>
  httpClient.patch(`/patients/${id}`, data).then(_ => _.data)

export const deletePatient = (id: any, params: any): Promise<any> =>
  httpClient.delete(`/patients/${id}`, { params }).then(_ => _.data)

export const importPatients = (data: any): Promise<any> =>
  httpClient
    .post('/patients/import', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(_ => _.data)

export const downloadPatients = (id: any): Promise<any> =>
  httpClient.get(`/patients-download-list?projectId=${id}&include=devices`);

export const getJobDetails = (params: any): Promise<any> => httpClient.get('/import-jobs', { params }).then(_ => _.data)
