import React, { FC, useState, createContext } from "react";
import download from "downloadjs";
import * as f from "./functions";
import { IPatientsContext } from "./interfaces";

const initialState = {
  patients: [],
  patient: {},
  jobs: [],
  job: [],
  error: null,
  loading: false,
  importPatientsResult: null,
};

const PatientsContext = createContext<IPatientsContext>({
  ...initialState,
  downloadPatients: () => {},
  getPatients: () => {},
  resetPatients: () => {},
  getPatient: () => {},
  getJob: () => {},
  resetJob: () => {},
  createPatient: () => {},
  importPatients: () => {},
  updatePatient: () => {},
  deletePatient: () => {},
  resetImportPatientsResult: () => {},
});

const ParticipantsProvider: FC = (props) => {
  const [state, setState] = useState(initialState);

  function reduce(data: any) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function downloadPatients(projectId: any) { f.downloadPatients(projectId) };

  async function createPatient(data: any, callback: any) {
    reduce({
      loading: true,
      importPatientsResult: null,
    });
    const res = await f.createPatient(data, callback);
    reduce(res);
  }

  async function deletePatient(id: any, params: any, callback: any) {
    reduce({ loading: true });
    const res = await f.deletePatient(id, params, callback);
    reduce(res);
  }

  async function updatePatient(id: any, data: any, callback: any) {
    reduce({ loading: true });
    const res = await f.updatePatient(id, data, callback);
    reduce(res);
  }

  async function getJob(projectId: any, jobId: any, callback: any) {
    reduce({ loading: true });
    const res = await f.getJob(projectId, jobId, callback);
    reduce(res);
  }

  async function getPatient(id: any, callback: any) {
    reduce({ loading: true });
    const res = await f.getPatient(id, callback);
    reduce(res);
  }

  async function getPatients(projectId: any, fields: any, include: any) {
    reduce({ loading: true });
    const res = await f.getPatients(projectId, fields, include);
    reduce(res);
  }

  async function importPatients(data: any) {
    reduce({
      loading: true,
      importPatientsResult: null,
    });
    const res = await f.importPatients(data);
    reduce(res);
  }

  const resetImportPatientsResult = (): void => reduce({ importPatientsResult: null });

  const resetJob = () => reduce({ job: [] });

  const resetPatients = () => reduce({ patients: [] });

  return (
    <PatientsContext.Provider
      value={{
        patients: state.patients,
        patient: state.patient,
        jobs: state.jobs,
        job: state.job,
        loading: state.loading,
        error: state.error,
        importPatientsResult: state.importPatientsResult,
        downloadPatients,
        getPatients,
        resetPatients,
        getPatient,
        getJob,
        resetJob,
        createPatient,
        updatePatient,
        importPatients,
        deletePatient,
        resetImportPatientsResult,
      }}
      {...props}
    />
  );
};

const useParticipants = (): IPatientsContext =>
  React.useContext(PatientsContext);

export { ParticipantsProvider, useParticipants };
