import moment from "moment";
import { ALERT_TYPES } from "utils/constants";



// ----------------------- Reducer --------------------------------------

export function reducer<T, U>(current: T, next: U) { return {...current, ...next}}

// ----------------------- Date Format ----------------------------------

export function dateFormat(date = moment(), type: number = 1) {
  const types = {
    1: "MM/DD/YY hhmm",
    2: "MM/DD/YY",
  };
   return moment(date).format(types[type]);
}

// ----------------------- HANDLE KEY PRESS ------------------------------

export function handleKeyPress(e, execute) {
  e.preventDefault()
  e.key === "Enter" && execute()
};

// ----------------------- DEBOUNCE --------------------------------------

export const debounce = function () {
  let timeoutId: any

  return {
     handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, func: any, time = 250) => {
      const {target: { value }} = e
      e.preventDefault()

      !!timeoutId && clearTimeout(timeoutId)

      if (value) {
        timeoutId = setTimeout(() => {
          func(value)
          timeoutId = undefined
        }, time)
      } else {
        func(value)
      }
    }
  }
}()

// ----------------------- Notification --------------------------------------

export function notification(message, callback, type = ALERT_TYPES.ERROR) {
  callback(message, {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    preventDuplicate: true,
    variant: type,
  });
}

// ----------------------- Copy to Clipboard -----------------------------------

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

// ----------------------- Is Internet Explorer? --------------------------------


export const isIE = (() => {
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie !== -1) {
    // IE 10 or older => return version number
    return !!parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)))
  }

  const trident = ua.indexOf('Trident/')
  if (trident !== -1) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return !!parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)))
  }

  const edge = ua.indexOf('Edge/')
  if (edge !== -1) {
    // Edge (IE 12+) => return version number
    return !!parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)))
  }

  // other browser
  return false
})()


// -----------------------------------------------------------------------------
// Type checks
// -----------------------------------------------------------------------------
export const isString = (value: any) => typeof value === 'string' || value instanceof String

export const isNumber = (value: any) => typeof value === 'number' && isFinite(value)

export const isArray = (value: any) => Array.isArray(value)

export const isFunction = (value: any) => typeof value === 'function'

export const isObject = (value: any) => value && typeof value === 'object' && value.constructor === Object

export const isNull = (value: any) => value === null

export const isUndefined = (value: any) => typeof value === 'undefined'

export const isBoolean = (value: any) => typeof value === 'boolean'

export const isRegExp = (value: any) => value && typeof value === 'object' && value.constructor === RegExp

export const isDate = (value: any) => value instanceof Date

export const isSymbol = (value: any) => typeof value === 'symbol'

// -----------------------------------------------------------------------------

export const capitalize = (s: string) => s.toLowerCase().replace(/\b./g, a => a.toUpperCase())

export function cloneDeep (e: any) { return JSON.parse(JSON.stringify(e)) }
