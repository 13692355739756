import React, { FC, useState, createContext } from 'react'

import * as devicesApi from 'services/devices'
// import Notification from 'utils/notification'
import * as _I from "utils/types";


const DevicesContext = createContext<_I.DevicesContext>({
  devices: [],
  device: {},
  loading: false,
  error: null,
  getDevices: () => {},
  getDevice: () => {console.log('abc')},
  addDevice: () => {},
  resetDevices: () => {},
  resetDevice: () => {},
})

const DevicesProvider = props => {
  const [state, setState] = useState({
    devices: [],
    device: {},
    error: null,
    loading: false,
  })

  const getDevices = async (params: any, callback?: any): Promise<void> => {
      console.log("result devices");
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }))

      const result = await devicesApi.getDevices(params)
      console.log('result devices', result)
      setState(prevState => ({
        ...prevState,
        loading: false,
        devices: result.items,
      }))

      if (callback) {
        await callback()
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get devices list',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const getDevice = async (id: any, callback?: any): Promise<void> => {
    try {
      const result = await devicesApi.getDevice(id)

      setState(prevState => ({
        ...prevState,
        device: result,
      }))

      if (callback) {
        await callback(result)
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get device details',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const addDevice = async (data: any, callback?: any): Promise<void> => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }))

      await devicesApi.addDevice(data)

      setState(prevState => ({
        ...prevState,
        loading: false,
      }))

      // Notification({
      //   type: 'success',
      //   message: 'New device was created!',
      // })

      if (callback) {
        await callback()
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
      }))

      // Notification({
      //   type: 'error',
      //   message: 'Cannot get device details',
      //   description: e?.response?.data?.error?.message || e.message,
      // })
    }
  }

  const resetDevices = (): void => {
    setState(prevState => ({
      ...prevState,
      devices: [],
    }))
  }

  const resetDevice = (): void => {
    setState(prevState => ({
      ...prevState,
      device: {},
    }))
  }

  return (
    <DevicesContext.Provider
      value={{
        devices: state.devices,
        device: state.device,
        loading: state.loading,
        error: state.error,
        getDevices,
        getDevice,
        addDevice,
        resetDevices,
        resetDevice,
      }}
      {...props}
    />
  )
}

const useDevices = (): _I.DevicesContext => React.useContext(DevicesContext)

export { DevicesProvider, useDevices }
