import React from "react";
import "../styles/footer.scss";

export default function () {
  return (
    <div className={"footer"}>
      {/* © 2020 Tasso, Inc. All Rights Reserved. PN0093-01, rev.07. */}
    </div>
  )
}
