import React, { useRef, useState } from "react";
import logo from "assets/Tasso_Lockup_ha.svg";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { useAuth } from "providers/Auth";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import "../styles/header.scss";
import { Link, useHistory } from "react-router-dom";
import { ROUTES as routes } from "utils/routes";

export default function () {
  const { logout, user } = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    const { current }: any = anchorRef;
    !current?.contains(event.target) && setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleLogout (event) {
    logout();
    handleClose(event);
  };

  return (
    <div className={"header"}>
      <img
        className={"logo"}
        src={logo} alt={logo}
        onClick={() => history.push(routes.PROJECTS.PATH)}
      />
      <div className={"menu"}>
        {!!user && (
          <>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className={"menu_title"}
            >
              <div className="menu_username">{user.username}</div>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              className={"menu_paper"}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      "center top",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleClose}>
                          <span
                            onClick={() => history.push(routes.CHANGE_PASSWORD.PATH)}
                          >
                            Change Password
                          </span>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
      </div>
    </div>
  );
}
