import { httpClient }  from 'services/index'

export const getProjects = (params?: any): Promise<any> => httpClient.get('/projects', { params }).then(_ => _.data)

export const getProject = (id: any): Promise<any> => httpClient.get(`/projects/${id}`).then(_ => _.data)

export const createProject = (data: any): Promise<any> => httpClient.post('/projects', data).then(_ => _.data)

export const updateProject = (id: any, data: any): Promise<any> =>
  httpClient.patch(`/projects/${id}`, data).then(_ => _.data)

export const createProjectDB = (data: any): Promise<any> =>
  httpClient.post('/create-project-database', data).then(_ => _.data)
