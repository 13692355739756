import axios from 'axios'

import Auth from '../utils/cognito'
import * as config from 'utils/config'

const addAuthHeaderInterceptor = async config => {
  if (config.ignoreAuthorization) {
    return config
  }

  try {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
  } catch (e) {
    console.log('Not authenticated', e)
  }

  return config
}

const httpClient = axios.create({
  baseURL: config.API_URL, //process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use(addAuthHeaderInterceptor)

const responseSuccessLogger = response => {
  const methodStr = response.config.method ? response.config.method.toUpperCase() : ''
  const urlStr = (response.config.baseURL || '') + (response.config.url || '')
  // console.info(`Response success: ${methodStr} ${urlStr}:`, response)
  return response
}

const responseErrorLogger = error => {
  const { response } = error
  const methodStr = response.config.method ? response.config.method.toUpperCase() : ''
  const urlStr = (response.config.baseURL || '') + (response.config.url || '')

  console.log(`Response error: ${methodStr} ${urlStr}:`, response)

  return Promise.reject(error)
}

httpClient.interceptors.response.use(responseSuccessLogger, responseErrorLogger)

export default httpClient
