import * as _I from "./types";

export const actionTypes: _I.Actions = {
  ENTER_CODE: "enterCode",
  SET_PASSWORD: "setPassword",
  SETUP_MFA: "setupMfa",
};

export const inputComponentTypes = {
  EMAIL: "email",
  PASSWORD: "password",
  TEXT: "text"
}

export const AWS_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  MFA_SETUP: "MFA_SETUP",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA" as _I.AWSChallengeTypes,
};

export const ALERT_TYPES: _I.Alerts = {
  DEFAULT: "default",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

export const errorTypes = {
  FILE_PARSE_ERROR: "FILE_PARSE_ERROR",
};

export const userInfo = {
  CODE: "Code",
  CURRENT_PASSWORD: "Current Pasword",
  EMAIL: "Email",
  ENTER_CODE: "Enter Passcode",
  KEEP_ME_SIGNED_IN: "KEEP ME SIGNED IN",
  MFA: "MFA",
  NEW_PASSWORD: "New Password",
  OLD_PASSWORD: "Old Password",
  PASSWORD: "Password",
  USERNAME: "Username",
};

export const colorTypes = {
  DEFAULT: "default" as "default",
};

export const inputTypes = {
  CHECKBOX: "checkbox",
  PASSWORD: "password",
  TEXT: "text",
};

export const buttonText = {
  CONFIRM: "Confirm",
  SIGN_IN: "Sign In",
};

export const PROJECT_STATUS: _I.LooseObject = {
  active: "active",
  fetching: "fetching",
  creating: "creating",
};

export const DEVICE_STATUS = {
  // unknown: "Unknown",
  inStock: "In Stock",
  inTransitToPatient: "In Transit To Patient",
  atPatient: "At patient",
  inTransitToLab: "In Transit To Lab",
  atLab: "At Lab",
  // returnToSender: "Return To Sender",
  // error: "Error",
  // deleted: "Deleted",
  // delivered: "Delivered",
};

export const DEVICE_STATUS_VALUES = Object.values(DEVICE_STATUS);

export enum DeviceStatuses {
  unknown = 0, // "Unknown", // Unassigned Device
  inStock = 1, // " In Stock" // At Tasso
  inTransitToPatient = 2, // In Transit To Patient
  atPatient = 3, // At patient
  inTransitToLab = 4, // "In Transit To Lab"
  atLab = 5, // "At Lab"
  returnToSender = 6, // "Return To Sender"
  error = 7, // "Error"
  deleted = 8, // "Deleted"
  delivered = 9, // "Delivered"
}

export const buttonTypes = {
  CANCEL: "cancel",
  PRIMARY: "primary",
  FILE_INPUT: "browse",
};

export const deviceStatusesArray = [
  { id: "inStock", text: "In Stock"},
  { id: "inTransitToPatient", text: "To Patient"},
  { id: "atPatient", text: "At Patient"},
  { id: "inTransitToLab", text: "To Lab"},
  { id: "atLab", text: "At Lab"},
];
